<template>
  <div>
    Email
  </div>
</template>

<script>

export default {
  name: 'Email'
}
</script>
